import type { ISbStoryData } from '@storyblok/js'

interface StoryblokContentBase<T extends string> {
  _uid?: string
  component?: T
  _editable?: string
  [index: string]: any
}
type StoryblokData<T> = ISbStoryData<StoryblokContentBase<string> & T>

interface Options<T> {
  apiOptions?: Record<string, unknown>
  bridgeOptions?: Record<string, unknown>
  additionalValidation?: (story: T) => boolean
}

export default async <T>(url: string, options?: Options<StoryblokData<T>>) => {
  const uniqueKey = `${JSON.stringify(options?.apiOptions)}${url}`
  const { locale } = useI18n()
  const story = useState<StoryblokData<T>>(`${uniqueKey}-state`)
  const storyblokApiInstance = useStoryblokApi()
  const config = useRuntimeConfig()
  const cacheStore = useCacheStore()
  // const navStore = useNavStore()
  const mainStore = useMainStore()

  onMounted(() => {
    mainStore.$patch({
      storyData: story.value,
    })
    if (story.value && story.value.id) {
      useStoryblokBridge(
        story.value.id,
        (evStory) => (story.value = evStory),
        options?.bridgeOptions,
      )
    }
  })
  const { data, error } = await useAsyncData(
    uniqueKey,
    async () =>
      await storyblokApiInstance.get(`cdn/stories/${url}`, {
        version: config.public.storyblokApiVersion as 'draft' | 'published',
        language: locale.value,
        ...options?.apiOptions,
      }),
  )
  story.value = data.value?.data?.story
  cacheStore.$patch({
    cacheVersion: data.value?.data?.cv,
  })

  if (!story.value || error.value) {
    throw createError({
      message: 'Story not found',
      statusCode: 404,
    })
  }

  const ts = story.value.translated_slugs
  const availableLanguages = story.value.content.available_languages

  if (ts !== undefined) {
    if (
      availableLanguages !== undefined &&
      availableLanguages.length > 0 &&
      !availableLanguages.includes(locale.value)
    ) {
      throw new Error('Not found the story in the current language')
    } else {
      const notTranslatedPages = ts.map((item) => {
        return !item.name ? item.lang : ''
      })

      notTranslatedPages?.forEach((lang) => {
        if (lang === locale.value) {
          throw new Error('Not found the story in the current language')
        }
      })
    }
  }

  const isInvalid =
    options?.additionalValidation && options.additionalValidation(story.value)

  if (isInvalid) {
    throw createError({
      message: 'Story validation failed',
      statusCode: 404,
    })
  }

  return { story, error }
}
